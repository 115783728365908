import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/shared/shared.module';
import { NgOptimizedImage } from '@angular/common';
import { interval, Subject, Subscription, takeUntil, tap } from 'rxjs';
import {
  AnimationLoader,
  AnimationOptions,
  LottieComponent,
  provideLottieOptions,
} from 'ngx-lottie';
import { User, UserService } from '../../shared/services/user.service';
import { GoldenQuestService } from '../../shared/services/golden-quest.service';
import { Badge } from '../quest/models/quest.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule, NgOptimizedImage, LottieComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  userInfo: any | User;
  isLoading = true;
  remainingTime = '';
  private timerSubscription: Subscription | null = null;

  options: AnimationOptions = {
    path: 'assets/animations/level-up.json',
    loop: false,
  };
  showAnimation = false;

  badges: Badge[] | any;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private goldenQuestService: GoldenQuestService
  ) {}

  ngOnInit() {
    localStorage.getItem('token') ? this.getUserInfo() : null;
    this.getBadges();
  }

  getUserInfo() {
    this.goldenQuestService
      .getUserInfo()
      .pipe(
        tap({
          next: (response) => {
            this.userInfo = response;
            this.userService.setUser(response);
            this.calculateFarmValue();
            this.updateCountdown();
          },
          error: (error) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getBadges() {
    this.goldenQuestService
      .getBadges()
      .pipe(
        tap({
          next: (response) => {
            this.badges = response;
          },
          error: (error) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  start() {
    this.isLoading = true;
    this.goldenQuestService
      .startFarming()
      .pipe(
        tap({
          next: (response) => {
            this.getUserInfo();
          },
          error: (error) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  claim() {
    this.stopCountdown();
    this.goldenQuestService
      .claimGoldenCoin()
      .pipe(
        tap({
          next: (response) => {
            this.getUserInfo();
            this.showConfettiAnimation();
          },
          error: (error) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  calculateFarmValue() {
    const totalFarmingTime = 18000.1; // exactly 5 hours in seconds

    // Check if leftTime is valid
    if (this.userInfo.leftTime === null) {
      this.userInfo.farmValue = 0; // No farming has started or time is up
    } else {
      const timeElapsed = totalFarmingTime - this.userInfo.leftTime;

      // Ensure we don't exceed the total farming time
      if (timeElapsed >= totalFarmingTime) {
        this.userInfo.farmValue = this.userInfo.maxFarmValue; // Farming is complete
      } else {
        this.userInfo.farmValue =
          (timeElapsed / totalFarmingTime) * this.userInfo.maxFarmValue;
      }
    }

    return this.userInfo.farmValue;
  }

  updateCountdown() {
    this.stopCountdown();
    this.timerSubscription = interval(1000).subscribe(() => {
      if (this.userInfo.leftTime > 0) {
        this.userInfo.leftTime--;
        this.remainingTime = this.formatTime(this.userInfo.leftTime);
      } else {
        this.stopCountdown();
      }
      this.isLoading = false;
    });
  }

  stopCountdown() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return hours > 0
      ? `${hours}h ${minutes}m`
      : minutes > 0
      ? `${minutes}m ${secs}s`
      : secs > 0
      ? `${minutes}m ${secs}s`
      : '5h 0m';
  }

  showConfettiAnimation() {
    this.showAnimation = true;
    setTimeout(() => {
      this.showAnimation = false;
    }, 3000);
  }

  getIconByBadge() {
    const iconName: string =
      this.badges?.find((badge: Badge) => badge.id === this.userInfo?.badge)
        ?.name || 'bronze';

    return iconName.toLowerCase();
  }

  getBadgeName() {
    return (
      this.badges?.find((badge: Badge) => badge.id === this.userInfo?.badge)
        ?.name || 'BRONZE'
    );
  }

  ngOnDestroy() {
    this.stopCountdown();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
