import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { QuestComponent } from './features/quest/quest.component';
import { FriendsComponent } from './features/friends/friends.component';
import { StoryComponent } from './features/story/story.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'quest', component: QuestComponent },
  { path: 'friends', component: FriendsComponent },
  { path: 'story', component: StoryComponent },
  { path: '**', redirectTo: '/home' }, // Wildcard route for any undefined paths
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
