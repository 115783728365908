<ng-lottie *ngIf="showAnimation" [options]="options"></ng-lottie>

<div class="container-farm">
  <div *ngIf="!isLoading" class="badge-view">
    <img
      ngSrc="../../../assets/images/lion-{{getIconByBadge()}}.png"
      alt="lion"
      class="icon"
      height="100"
      width="100"
      priority="high"
    />
    <h3 class="badge-name">{{ getBadgeName() }}</h3>
  </div>
  <ng-container *ngIf="!isLoading">
    <div *ngIf="userInfo" class="container-body">
      <div class="name-n-amount">
        <h2>{{ userInfo?.name }}</h2>
        <h2 class="balance">{{ userInfo?.balance | number: "1.0-0" }}
          <img
            ngSrc="assets/icons/coin.png"
            width="15"
            height="15"
            alt="coin"
          /></h2>
      </div>
      <div class="time-box">
        <p *ngIf="userInfo?.farmValue > 0 && remainingTime">
          <span class="gold">{{ calculateFarmValue()?.toFixed(3) }}
            <img
              ngSrc="assets/icons/coin.png"
              width="15"
              height="15"
              alt="coin"
            />
          </span>
          <span *ngIf="userInfo?.farmValue !== userInfo?.maxFarmValue" class="time"> - {{ remainingTime }} ⏳</span>
        </p>
      </div>
    </div>
    <div class="btn">
      <button
        *ngIf="userInfo?.farmValue <= 0"
        class="glowing-btn started"
        (click)="start()"
      >
        <span class="glowing-txt"
        >S<span class="faulty-letter">t</span>art</span
        >
      </button>
      <button
        *ngIf="userInfo?.farmValue > 0 && userInfo?.farmValue < userInfo?.maxFarmValue"
        class="glowing-btn"
      >
        <span class="glowing-txt"
        >F<span class="faulty-letter">a</span>rming</span
        >
      </button>
      <button
        *ngIf="userInfo?.farmValue === userInfo?.maxFarmValue"
        class="glowing-btn started"
        (click)="claim()"
      >
        <span class="glowing-txt"
        >C<span class="faulty-letter">l</span>aim</span
        >
      </button>
    </div>
  </ng-container>
  <div *ngIf="isLoading" class="loader"></div>
</div>
