import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
class TwaButton {
  constructor(button) {
    this.button = button;
    this.clicks$ = new Observable(subscriber => {
      const clickHandler = subscriber.next.bind(subscriber);
      button.onClick(clickHandler);
      return () => {
        button.offClick(clickHandler);
      };
    });
  }
  /**
   *  A method to make the button active and visible.
   */
  show() {
    this.button.show();
  }
  /**
   *  A method to hide the button.
   */
  hide() {
    this.button.hide();
  }
}
class BackButton extends TwaButton {
  get isVisible() {
    return this.button.isVisible;
  }
}
class CloudStorage {
  constructor(storage) {
    this.storage = storage;
  }
  /**
   * Bot API 6.9+ A method that stores a value in the cloud storage using the specified key.
   *
   * The key should contain 1-128 characters, only A-Z, a-z, 0-9, _ and - are allowed.
   *
   * The value should contain 0-4096 characters. You can store up to 1024 keys in the cloud storage.
   */
  setItem(key, value) {
    return new Observable(subscriber => {
      this.storage.setItem(key, value, (error, success) => {
        if (error) {
          subscriber.error(error);
          return;
        }
        subscriber.next(success);
        subscriber.complete();
      });
    });
  }
  /**
   * Bot API 6.9+ A method that receives a value from the cloud storage using the specified key.
   *
   * The key should contain 1-128 characters, only A-Z, a-z, 0-9, _ and - are allowed.
   */
  getItem(key) {
    return new Observable(subscriber => {
      this.storage.getItem(key, (error, value) => {
        if (error) {
          subscriber.error(error);
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      });
    });
  }
  /**
   * Bot API 6.9+ A method that receives values from the cloud storage using the specified keys.
   *
   * The keys should contain 1-128 characters, only A-Z, a-z, 0-9, _ and - are allowed.
   */
  getItems(keys) {
    return new Observable(subscriber => {
      this.storage.getItems(keys, (error, values) => {
        if (error) {
          subscriber.error(error);
          return;
        }
        subscriber.next(values);
        subscriber.complete();
      });
    });
  }
  /**
   * Bot API 6.9+ A method that removes a value from the cloud storage using the specified key.
   *
   * The key should contain 1-128 characters, only A-Z, a-z, 0-9, _ and - are allowed.
   */
  removeItem(key) {
    return new Observable(subscriber => {
      this.storage.removeItem(key, (error, success) => {
        if (error) {
          subscriber.error(error);
          return;
        }
        subscriber.next(success);
        subscriber.complete();
      });
    });
  }
  /**
   * Bot API 6.9+ A method that removes values from the cloud storage using the specified keys.
   *
   * The keys should contain 1-128 characters, only A-Z, a-z, 0-9, _ and - are allowed.
   */
  removeItems(keys) {
    return new Observable(subscriber => {
      this.storage.removeItems(keys, (error, success) => {
        if (error) {
          subscriber.error(error);
          return;
        }
        subscriber.next(success);
        subscriber.complete();
      });
    });
  }
  /**
   *  Bot API 6.9+ A method that receives the list of all keys stored in the cloud storage.
   */
  getKeys() {
    return new Observable(subscriber => {
      this.storage.getKeys((error, keys) => {
        if (error) {
          subscriber.error(error);
          return;
        }
        subscriber.next(keys);
        subscriber.complete();
      });
    });
  }
}
class HapticFeedback {
  constructor(haptic) {
    this.haptic = haptic;
  }
  /**
   * Bot API 6.1+ A method tells that an impact occurred. The Telegram app may play the appropriate haptics based on the style value passed.
   */
  impactOccurred(style) {
    this.haptic.impactOccurred(style);
    return this;
  }
  /**
   * Bot API 6.1+ A method tells that a task or action has succeeded, failed, or produced a warning. The Telegram app may play the appropriate haptics based on the type value passed.
   */
  notificationOccurred(type) {
    this.haptic.notificationOccurred(type);
    return this;
  }
  /**
   * Bot API 6.1+ A method tells that the user has changed a selection. The Telegram app may play the appropriate haptics.
   *
   * Do not use this feedback when the user makes or confirms a selection; use it only when the selection changes.
   */
  selectionChanged() {
    this.haptic.selectionChanged();
    return this;
  }
}
class MainButton extends TwaButton {
  /**
   * Current button text
   */
  get text() {
    return this.button.text;
  }
  /**
   * Current button color.
   */
  get color() {
    return this.button.color;
  }
  /**
   * Current button text color.
   */
  get textColor() {
    return this.button.textColor;
  }
  /**
   * Shows whether the button is visible. Set to false by default.
   */
  get isVisible() {
    return this.button.isVisible;
  }
  /**
   * Shows whether the button is active. Set to true by default.
   */
  get isActive() {
    return this.button.isActive;
  }
  /**
   *  Shows whether the button is displaying a loading indicator.
   */
  get isProgressVisible() {
    return this.button.isProgressVisible;
  }
  /**
   * A method to set the button text.
   */
  setText(text) {
    this.button.setText(text);
    return this;
  }
  /**
   *  A method to enable the button.
   */
  enable() {
    this.button.enable();
    return this;
  }
  /**
   * A method to disable the button.
   */
  disable() {
    this.button.disable();
    return this;
  }
  /**
   * A method to show a loading indicator on the button.
   * @param leaveActive  when leaveActive=true is passed, the button remains enabled.
   */
  showProgress(leaveActive = false) {
    this.button.showProgress(leaveActive);
    return this;
  }
  /**
   * Shows whether the button is active. Set to true by default.
   */
  hideProgress() {
    this.button.hideProgress();
    return this;
  }
  /**
   * A method to set the button parameters. The params parameter is an object containing one or several fields that need to be changed:
   * - text - button text;
   * - color - button color;
   * - text_color - button text color;
   * - is_active - enable the button;
   * - is_visible - show the button.
   */
  setParams(params) {
    this.button.setParams(params);
    return this;
  }
}
const TWA = new InjectionToken('instance of telegram webapp', {
  providedIn: 'root',
  factory: () => {
    if (!Telegram || !Telegram.WebApp) {
      throw new Error('Telegram context not found. Initialize mini app first: https://core.telegram.org/bots/webapps#initializing-mini-apps');
    }
    return Telegram.WebApp;
  }
});
class TelegramWebappService {
  constructor() {
    this.webApp = inject(TWA);
    this.backButton = new BackButton(this.webApp.BackButton);
    this.mainButton = new MainButton(this.webApp.MainButton);
    this.hapticFeedback = new HapticFeedback(this.webApp.HapticFeedback);
    this.cloudStorage = new CloudStorage(this.webApp.CloudStorage);
  }
  /**
   * A string with raw data transferred to the Mini App, convenient for validating data.
   */
  get initData() {
    return this.webApp.initData;
  }
  /**
   * An object with input data transferred to the Mini App.
   */
  get initDataUnsafe() {
    return this.webApp.initDataUnsafe;
  }
  /**
   * The version of the Bot API available in the user's Telegram app.
   */
  get webappVersion() {
    return this.webApp.version;
  }
  /**
   * The name of the platform of the user's Telegram app.
   */
  get platform() {
    return this.webApp.platform;
  }
  /**
   * The color scheme currently used in the Telegram app. Either "light" or "dark". Also available as the CSS variable var(--tg-color-scheme).
   */
  get colorScheme() {
    return this.webApp.colorScheme;
  }
  /**
   * An object containing the current theme settings used in the Telegram app.
   */
  get themeParams() {
    return this.webApp.themeParams;
  }
  /**
   * True, if the Mini App is expanded to the maximum available height. False, if the Mini App occupies part of the screen and can be expanded to the full height using the expand() method.
   */
  get isExpanded() {
    return this.webApp.isExpanded;
  }
  /**
   * The current height of the visible area of the Mini App. Also available in CSS as the variable var(--tg-viewport-height).
   */
  get viewportHeight() {
    return this.webApp.viewportHeight;
  }
  /**
   * The height of the visible area of the Mini App in its last stable state. Also available in CSS as a variable var(--tg-viewport-stable-height).
   */
  get viewportStableHeight() {
    return this.webApp.viewportStableHeight;
  }
  /**
   * Current header color in the #RRGGBB format.
   */
  get headerColor() {
    return this.webApp.headerColor;
  }
  /**
   * Current background color in the #RRGGBB format.
   */
  get backgroundColor() {
    return this.webApp.backgroundColor;
  }
  /**
   * True, if the confirmation dialog is enabled while the user is trying to close the Mini App. False, if the confirmation dialog is disabled.
   */
  get isClosingConfirmationEnabled() {
    return this.webApp.isClosingConfirmationEnabled;
  }
  /**
   * A method that informs the Telegram app that the Mini App is ready to be displayed.
   */
  ready() {
    this.webApp.ready();
  }
  /**
   * A method that expands the Mini App to the maximum available height.
   */
  expand() {
    if (this.webApp && !this.webApp.isExpanded) {
      this.webApp.expand();
    }
  }
  /**
   * A method that closes the Mini App.
   */
  close() {
    this.webApp.close();
  }
  /**
   * Function that sets the app event handler.
   * @param event - The mini app events. {@link https://core.telegram.org/bots/webapps#events-available-for-mini-apps list of available events}
   * @returns a data depends on event type
   */
  onEvent(event) {
    return new Observable(subscriber => {
      const cb = subscriber.next.bind(subscriber);
      this.webApp.onEvent(event, cb);
      return () => {
        this.webApp.offEvent(event, cb);
      };
    });
  }
  /**
   *  Function that sets the app header color in the #RRGGBB format.
   */
  setHeaderColor(color) {
    this.webApp.setHeaderColor(color);
  }
  /**
   *  Function that sets the app background color in the #RRGGBB format.
   */
  setBackgroundColor(color) {
    this.webApp.setBackgroundColor(color);
  }
  /**
   * Function that enables a confirmation dialog while the user is trying to close the Mini App.
   */
  enableClosingConfirmation() {
    this.webApp.enableClosingConfirmation();
  }
  /**
   * Function that disables the confirmation dialog while the user is trying to close the Mini App.
   */
  disableClosingConfirmation() {
    this.webApp.disableClosingConfirmation();
  }
  /**
   * Function used to send data to the bot. When this method is called, a
   * service message is sent to the bot containing the data of the length
   * up to 4096 bytes, and the Mini App is closed. See the field web_app_data
   * in the class Message. This method is only available for Mini Apps launched
   * via a Keyboard button.
   */
  sendData(data) {
    const payload = JSON.stringify(data || '');
    this.webApp.sendData(payload);
  }
  /**
   * Function that shows a native popup for scanning a QR code described by the params argument of the type ScanQrPopupParams.
   */
  showScanQrPopup(params = {}) {
    return new Observable(subscriber => {
      this.webApp.showScanQrPopup(params, subscriber.next.bind(subscriber));
      return () => {
        this.webApp.closeScanQrPopup();
      };
    });
  }
  /**
   * Function that closes the native popup for scanning a QR code opened with the showScanQrPopup method.
   */
  closeScanQrPopup() {
    this.webApp.closeScanQrPopup();
  }
  /**
   * Function that opens a link in an external browser.
   */
  openLink(url, options) {
    this.webApp.openLink(url, options);
  }
  /**
   * Function that opens a telegram link inside Telegram app. The Mini App will be closed.
   */
  openTelegramLink(url) {
    return this.webApp.openTelegramLink(url);
  }
  /**
   * Function that opens an invoice using the link url.
   */
  openInvoice(url) {
    return new Observable(subscriber => {
      this.webApp.openInvoice(url, status => {
        subscriber.next(status);
        subscriber.complete();
      });
    });
  }
  /**
   * Function that shows a native popup described by the params argument of the type PopupParams.
   */
  showPopup(params) {
    return new Observable(subscriber => {
      this.webApp.showPopup(params, buttonId => {
        subscriber.next(buttonId);
        subscriber.complete();
      });
    });
  }
  /**
   * Function that shows a native popup described by the params argument of the type PopupParams.
   */
  showAlert(msg) {
    return new Observable(subscriber => {
      this.webApp.showAlert(msg, () => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }
  /**
   * Function that shows message in a simple confirmation window with 'OK' and 'Cancel' buttons.
   */
  showConfirm(msg) {
    return new Observable(subscriber => {
      this.webApp.showConfirm(msg, okButtonPressed => {
        subscriber.next(okButtonPressed);
        subscriber.complete();
      });
    });
  }
  /**
   * Function that shows a native popup requesting permission for the bot to send messages to the user.
   */
  requestWriteAccess() {
    return new Observable(subscriber => {
      this.webApp.requestWriteAccess(isAccessGranted => {
        subscriber.next(isAccessGranted);
        subscriber.complete();
      });
    });
  }
  /**
   * Function that inserts the bot's username and the specified inline query in the current chat's input field.
   */
  switchInlineQuery(query = '', chatTypes) {
    this.webApp.switchInlineQuery(query, chatTypes);
  }
  /**
   * Function that shows a native popup prompting the user for their phone number.
   */
  requestContact() {
    return new Observable(subscriber => {
      this.webApp.requestContact(isContactShared => {
        subscriber.next(isContactShared);
        subscriber.complete();
      });
    });
  }
  /**
   *  Function that requests text from the clipboard.
   */
  readTextFromClipboard() {
    return new Observable(subscriber => {
      this.webApp.readTextFromClipboard(clipboardText => {
        subscriber.next(clipboardText);
        subscriber.complete();
      });
    });
  }
  static {
    this.ɵfac = function TelegramWebappService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TelegramWebappService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TelegramWebappService,
      factory: TelegramWebappService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TelegramWebappService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-telegram-webapp
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TelegramWebappService };
