<div class="container" [class.center]="!isMobile">
  <ng-container *ngIf="isMobile">
    <div class="body">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </ng-container>
  <div *ngIf="!isMobile" class="text-info">
    <img
      ngSrc="assets/images/lion-bronze.png"
      alt="lion"
      class="icon"
      height="100"
      width="100"
      priority="high"
    />
    <h2>
      Access this application exclusively on mobile devices. Please switch to a phone or tablet for the best experience
    </h2>
  </div>
</div>
