import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TelegramWebappService } from '@zakarliuka/ng-telegram-webapp';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private telegramService: TelegramWebappService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 401) {
          // Re-authenticate the user
          return from(
            this.authService.loginUser(this.telegramService.initDataUnsafe)
          ).pipe(
            switchMap((res: any) => {
              // Store the new token and update the request
              localStorage.setItem('token', res.token);

              // Clone the original request and set the new token in headers
              const clonedRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${res.token}`,
                },
              });

              // Retry the original request with the new token
              return next.handle(clonedRequest);
            }),
            catchError((loginError) => {
              console.error('Error saving user:', loginError);
              // Handle any errors that occur during login
              return throwError(() => loginError);
            })
          );
        }
        return throwError(() => error);
      })
    );
  }
}
