import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { User } from './user.service';
import { Badge, Level } from '../../features/quest/models/quest.model';

@Injectable({
  providedIn: 'root',
})
export class GoldenQuestService {
  constructor(private http: HttpClient) {}

  getUserInfo(): Observable<any | User> {
    return this.http.get(`${environment.endpoint}/GoldenCoin/GetInfo`);
  }

  getLevels(): Observable<Level[] | any> {
    return this.http.get(`${environment.endpoint}/GoldenCoin/GetLevels`);
  }

  getBadges(): Observable<Badge[] | any> {
    return this.http.get(`${environment.endpoint}/GoldenCoin/GetBadges`);
  }

  startFarming(): Observable<any> {
    return this.http.post(
      `${environment.endpoint}/GoldenCoin/StartFarming`,
      {}
    );
  }

  claimGoldenCoin(): Observable<any> {
    return this.http.post(`${environment.endpoint}/GoldenCoin/Claim`, {});
  }

  buyLevel(level: number): Observable<any> {
    return this.http.post(`${environment.endpoint}/GoldenCoin/BuyLevel`, {
      level,
    });
  }

  buyBadge(badge: number): Observable<any> {
    return this.http.post(`${environment.endpoint}/GoldenCoin/BuyBadge`, {
      badge,
    });
  }
}
