import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user?: any | User;

  constructor() {}

  getUser(): User {
    return <User>this.user;
  }

  setUser(user: User): void {
    this.user = user;
  }
}

export interface User {
  id?: number;
  name: string;
  balance: number;
  farmValue: number;
  maxFarmValue: number;
  level: number;
  leftTime?: number | null;
  badge: number;
}
