import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TelegramWebappService } from '@zakarliuka/ng-telegram-webapp';
import { AuthService } from './core/services/auth.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { retrieveLaunchParams } from '@telegram-apps/sdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(e: KeyboardEvent) {
  //   if (e.key === 'F12') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === 'I') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === 'C') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === 'J') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.key == 'U') {
  //     return false;
  //   }
  //   return true;
  // }

  isMobile: boolean = true;
  private initData: any;
  private unsafeData: any;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private readonly telegramService: TelegramWebappService
  ) {
    // this.isMobile = this.checkIfMobile();
    // document.addEventListener('contextmenu', function (e) {
    //   e.preventDefault();
    // });
    this.telegramService.setHeaderColor('#000000');
    this.telegramService.setBackgroundColor('#000000');
    this.telegramService.enableClosingConfirmation();
    this.telegramService.ready();
  }

  checkIfMobile(): boolean {
    const userAgent = navigator.userAgent;
    const isMobileDevice =
      /Mobi|Android|iPhone|iPad|iPod|Kindle|Silk|Opera Mini|IEMobile|BlackBerry|BB10|webOS|Windows Phone/i.test(
        userAgent
      );
    const isSmallScreen = window.matchMedia('(max-width: 1024px)').matches;
    const hasTouchSupport =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const hasPointerEvents = window.PointerEvent !== undefined;
    const isHighResolution = window.devicePixelRatio > 1;

    return (
      isMobileDevice &&
      isSmallScreen &&
      hasTouchSupport &&
      hasPointerEvents &&
      isHighResolution
    );
  }

  ngOnInit(): void {
    this.getTelegramUserData();
    this.telegramService.expand();
  }

  getTelegramUserData() {
    this.unsafeData = this.telegramService.initDataUnsafe;
    this.initData = this.telegramService.initData;
    this.unsafeData = retrieveLaunchParams();
    if (this.unsafeData) {
      this.getBearerTokenByUserData();
    }
  }

  getBearerTokenByUserData() {
    this.authService
      .loginUser(this.unsafeData)
      .pipe(
        tap({
          next: (res) => {
            localStorage.setItem('token', res.token);
          },
          error: (err) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
