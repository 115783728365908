<div class="quest-container">
  <h2>
    Complete Your Quest and Unlock New Levels and Badges of Farming Power!
  </h2>
  <div class="pages">
    <div class="page" [class.active]="showLevels" (click)="showLevels = true">
      Levels
    </div>
    <div class="page" [class.active]="!showLevels" (click)="showLevels = false">
      Badges
    </div>
  </div>
  <div *ngIf="showLevels && !isLoading" class="levels">
    <p>
      Unlock up to 5 levels and boost your daily farming capacity. Start small,
      power up your progress, and grow your farm to reach the ultimate goal!
    </p>
    <!-- Level 1 Card -->

    <ng-container *ngFor="let level of levels; index as i">
      <div class="card level-{{ level.levelId }}">
        <div class="card-icon">
          <img
            ngSrc="assets/images/lion-gold.png"
            width="48"
            height="48"
            alt="Level 1 Icon"
          />
        </div>
        <div>
          <h2>Lvl {{ level.levelId }}</h2>
          <p>
            Farm - {{ level.maxFarm }}
            <img
              ngSrc="assets/icons/coin.png"
              width="15"
              height="15"
              alt="coin"
            />
          </p>
          <button
            *ngIf="userInfo?.level < level.levelId"
            [class.disabled]="userInfo?.level !== i"
            [disabled]="userInfo?.level !== i"
            (click)="unlockLevel(level.levelId)"
          >
            {{
              level?.price
            }}<img
            ngSrc="assets/icons/coin.png"
            width="15"
            height="15"
            alt="coin"
          />
          </button>
          <h3 *ngIf="userInfo?.level >= level.levelId">Unlocked</h3>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!showLevels && !isLoading" class="levels">
    <p>
      Level up your farming power! Each badge boosts your daily capacity,
      helping you reach new milestones faster. Keep pushing forward and multiply
      your farming impact!
    </p>

    <ng-container *ngFor="let badge of badges; index as i">
      <div class="card {{badge?.name.toLowerCase()}}">
        <div class="card-icon">
          <img
            ngSrc="assets/images/lion-{{badge?.name.toLowerCase()}}.png"
            width="48"
            height="48"
            alt="Badge {{badge?.name}} Icon"
          />
        </div>
        <div>
          <h2>{{ badge?.name }}</h2>
          <p>Farm - x{{ badge?.multiplier }}</p>
          <button
            *ngIf="userInfo?.badge < badge.id"
            [class.disabled]="userInfo?.badge !== i"
            [disabled]="userInfo?.badge !== i"
            (click)="unlockBadgeByCoins(badge.id)"
          >
            {{ badge.price | numberWithSpaces }}
            <img
              ngSrc="assets/icons/coin.png"
              width="15"
              height="15"
              alt="coin"
            />
          </button>
          <h3 *ngIf="userInfo?.badge >= badge.id">Unlocked</h3>
        </div>
      </div>
    </ng-container>

    <div class="card crimson">
      <div class="crimson-box">
        <div class="card-icon">
          <img
            ngSrc="assets/images/lion-crimson.png"
            width="48"
            height="48"
            alt="Level 1 Icon"
          />
        </div>
        <div>
          <h2>{{ badgeCrimson?.name }}</h2>
          <p>Farm - <span>x{{badgeCrimson?.multiplier}}</span></p>
          <button
            *ngIf="userInfo?.badge < badgeCrimson.id"
            [class.disabled]="userInfo?.badge !== badgeCrimson.id - 1"
            [disabled]="userInfo?.badge !== badgeCrimson.id - 1"
            (click)="payTonForCrimson(CrimsonEnum.COIN)"
          >
            {{badgeCrimson?.price | numberWithSpaces }} - Invite 10 Friends<img
            ngSrc="assets/icons/coin.png"
            width="15"
            height="15"
            alt="coin"
          />
          </button>
          <h3 *ngIf="userInfo?.badge >= badgeCrimson.id">Unlocked</h3>
        </div>
        <p *ngIf="userInfo?.badge < badgeCrimson.id">or</p>
        <button
          *ngIf="userInfo?.badge < badgeCrimson.id"
          class="pay"
          (click)="payTonForCrimson(CrimsonEnum.TON)"
        >
          Pay 1 TON - Invite 10 Friends
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="loader"></div>
</div>
