export interface Level {
  levelId: number;
  maxFarm: number;
  price: number;
}

export interface Badge {
  id: number;
  name: string;
  price: number;
  multiplier: number;
}

export enum CrimsonEnum {
  COIN = 1,
  TON = 2,
}
