<div class="friends-container">
  <mat-icon>diversity_2</mat-icon>
  <h2>Invite Your Friends and Earn <span>Golden Coins!</span> 🌟</h2>

  <div class="cards-container">
    <div class="card">
      <div class="card-content">
        <h3>Invite <span>5</span> Friends</h3>
        <p>
          Get
          <strong
            >250
            <img ngSrc="assets/icons/coin.png" width="15" height="15" alt="coin"
          /></strong>
        </p>
      </div>
      <button class="invite-button">Invite Now</button>
    </div>

    <div class="cards-container">
      <div class="card">
        <div class="card-content">
          <h3>Invite <span>10</span> Friends</h3>
          <p>
            Get
            <strong
              >500
              <img
                ngSrc="assets/icons/coin.png"
                width="15"
                height="15"
                alt="coin"
            /></strong>
          </p>
        </div>
        <button class="invite-button">Invite Now</button>
      </div>

      <div class="card">
        <div class="card-content">
          <h3>Invite <span>20</span> Friends</h3>
          <p>
            Get
            <strong
              >1500
              <img
                ngSrc="assets/icons/coin.png"
                width="15"
                height="15"
                alt="coin"
            /></strong>
          </p>
        </div>
        <button class="invite-button">Invite Now</button>
      </div>

      <div class="card">
        <div class="card-content">
          <h3>Invite <span>50</span> Friends</h3>
          <p>
            Get
            <strong
              >6500
              <img
                ngSrc="assets/icons/coin.png"
                width="15"
                height="15"
                alt="coin"
            /></strong>
          </p>
        </div>
        <button class="invite-button">Invite Now</button>
      </div>
    </div>
  </div>
</div>
