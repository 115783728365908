import { Component, OnDestroy, OnInit } from '@angular/core';
import { DecimalPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { User, UserService } from '../../shared/services/user.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { GoldenQuestService } from '../../shared/services/golden-quest.service';
import { Badge, CrimsonEnum, Level } from './models/quest.model';
import { SharedModule } from '../../shared/shared/shared.module';

@Component({
  selector: 'app-quest',
  standalone: true,
  imports: [NgForOf, DecimalPipe, NgOptimizedImage, NgIf, SharedModule],
  templateUrl: './quest.component.html',
  styleUrl: './quest.component.scss',
})
export class QuestComponent implements OnInit, OnDestroy {
  userInfo: User | any;
  levels: Level[] | any;
  badges: Badge[] | any;
  badgeCrimson: Badge | any;

  isLoading: boolean = true;
  showLevels: boolean = true;
  protected readonly CrimsonEnum = CrimsonEnum;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private goldenQuestService: GoldenQuestService
  ) {}

  ngOnInit() {
    this.getLevels();
    this.getBadges();
    if (this.userService.getUser()) {
      this.userInfo = this.userService.getUser();
      this.isLoading = false;
    } else {
      this.getUserInfo();
    }
  }

  getUserInfo() {
    this.goldenQuestService
      .getUserInfo()
      .pipe(
        tap({
          next: (response) => {
            this.userInfo = response;
            this.userService.setUser(response);
            this.isLoading = false;
          },
          error: (error) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getLevels() {
    this.goldenQuestService
      .getLevels()
      .pipe(
        tap({
          next: (response) => {
            this.levels = response;
          },
          error: (error) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getBadges() {
    this.goldenQuestService
      .getBadges()
      .pipe(
        tap({
          next: (response) => {
            this.badges = response.filter((b: any) => b.id !== 6);
            this.badgeCrimson = response.find((b: any) => b.id === 6);
          },
          error: (error) => {},
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  unlockLevel(level: number) {
    const levelInfo: Level = this.levels.find((l: any) => l.levelId === level);

    if (this.userInfo.balance >= levelInfo.price) {
      this.goldenQuestService
        .buyLevel(level)
        .pipe(
          tap({
            next: (response) => {
              this.toastr.success(`Level ${level} unlocked!`);
              this.isLoading = true;
              this.getUserInfo();
            },
            error: (error) => {
              this.toastr.error('An error occurred!');
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toastr.error('Insufficient balance!');
    }
  }

  unlockBadgeByCoins(id: number) {
    const badge: Badge = this.badges.find((b: any) => b.id === id);
    if (this.userInfo.balance >= badge.price) {
      this.goldenQuestService
        .buyBadge(id)
        .pipe(
          tap({
            next: (response) => {
              this.isLoading = true;
              this.getUserInfo();
              this.toastr.success(`Badge ${badge.name} unlocked!`);
            },
            error: (error) => {
              this.toastr.error('An error occurred!');
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toastr.error('Insufficient balance!');
    }
  }

  payTonForCrimson(type: number) {
    alert(type);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
