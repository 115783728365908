import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../modules/material.module';
import { NumberWithSpacesPipe } from '../pipes/number-with-spaces.pipe';

@NgModule({
  declarations: [NumberWithSpacesPipe],
  imports: [CommonModule, MaterialModule],
  exports: [MaterialModule, CommonModule, NumberWithSpacesPipe],
})
export class SharedModule {}
