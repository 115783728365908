<footer class="footer">
  <nav class="navbar">
    <a  routerLink="/home" routerLinkActive="active-link" aria-label="home">
      <mat-icon>home</mat-icon>
      <div>Home</div>
    </a>
    <a routerLink="/quest" routerLinkActive="active-link" aria-label="quest">
      <mat-icon>receipt_long</mat-icon>
      <div>Quest</div>
    </a>
    <a  routerLink="/friends" routerLinkActive="active-link" aria-label="friends">
      <mat-icon>diversity_3</mat-icon>
      <div>Friends</div>
    </a>
    <a  routerLink="/story" routerLinkActive="active-link" aria-label="story">
      <mat-icon>auto_stories</mat-icon>
      <div>Story</div>
    </a>
  </nav>
</footer>
