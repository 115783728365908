import { Component } from '@angular/core';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-friends',
  standalone: true,
  imports: [NgIf, NgOptimizedImage, MatIcon],
  templateUrl: './friends.component.html',
  styleUrl: './friends.component.scss',
})
export class FriendsComponent {}
